//ADMIN LOGIN

export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";



//NEWREGISTER

export const NEW_REGISTER_REQUEST = "NEW_REGISTER_REQUEST";
export const NEW_REGISTER_SUCCESS = "NEW_REGISTER_SUCCESS";
export const NEW_REGISTER_FAIL = "NEW_REGISTER_FAIL";
export const NEW_REGISTER_LOGOUT = "NEW_REGISTER_LOGOUT";


//NEWREGISTER

export const NEW_REGISTER_UPDATE_REQUEST = "NEW_REGISTER_UPDATE_REQUEST";
export const NEW_REGISTER_UPDATE_SUCCESS = "NEW_REGISTER_UPDATE_SUCCESS";
export const NEW_REGISTER_UPDATE_FAIL = "NEW_REGISTER_UPDATE_FAIL";
export const NEW_REGISTER_UPDATE_LOGOUT = "NEW_REGISTER_UPDATE_LOGOUT";


//PAYMENT

export const PAYMENT_REQUEST = "PAYMENT_REQUEST";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAIL = "PAYMENT_FAIL";
export const PAYMENT_LOGOUT = "PAYMENT_LOGOUT";


//COUNTRY

export const COUNTRY_REQUEST = "COUNTRY_REQUEST";
export const COUNTRY_SUCCESS = "COUNTRY_SUCCESS";
export const COUNTRY_FAIL = "COUNTRY_FAIL";
