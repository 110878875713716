import axios from "axios";
const BASE_URL = process.env.REACT_APP_API;
const user = JSON.parse(localStorage.getItem("persist:root"))?.admin;
const isadmin = user && JSON.parse(user)?.admin?.token;
//token request
export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    authorization: `Bearer ${isadmin}`,
  },
});
//without token request
export const getRequest = axios.create({
  baseURL: BASE_URL,
});
//public request
export const publicRequest = axios.create({
  baseURL: BASE_URL,
});






