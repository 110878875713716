import API from "./ApiService";

class crud_service {
  constructor() {
    this.api = new API();
  }

  //getall function
  getAll = async (url,params,callback) => {
    await this.api.getAll(url,params).then((res)=>{
      callback(null,res)
    }).catch((err)=>{
      callback(err,null)
    });
  };

  //getsingle function
  getSingle = async (url,params,callback) => {
     await this.api.getSingle(url,params).then((res)=>{
      callback(null,res)
    }).catch((err)=>{
      callback(err,null)
    });
  };

  //create function
  create = async (url,data,callback) => {
    await this.api.create(url,data).then((res)=>{
      callback(null,res)
    }).catch((err)=>{
      callback(err,null)
    });
  };

  //update function
  update = async (url,params,data,callback) => {
    await this.api.update(url,params,data).then((res)=>{
      callback(null,res)
    }).catch((err)=>{
      callback(err,null)
    });
  };

  
  updateStatus = async (url, data,callback) => {
    await this.api
      .updateStatus(url,data)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //remove function
  remove = async (url,params,callback) => {
    await this.api.remove(url,params).then((res)=>{
      callback(null,res)
    }).catch((err)=>{
      callback(err,null)
    });
  };

  //removeimage function
  removeimage = async (url,params,body,callback) => {
    await this.api.removeimage(url,params,body).then((res)=>{
      callback(null,res)
    }).catch((err)=>{
      callback(err,null)
    });
  };


  //create common function
  createCommon = async (url,data,callback) => {
    await this.api.createCommon(url,data).then((res)=>{
      callback(null,res)
    }).catch((err)=> {
      callback(err,null)
    });
  };

  //getcommonall function
  getCommonAll = async (url,params,callback) => {
    await this.api.getCommonAll(url,params).then((res)=>{
      callback(null,res)
    }).catch((err)=>{
      callback(err,null)
    });
  };

  

};


export default crud_service;
