import React, { useEffect, useState, useRef } from 'react'
import './Assets/Css/style.css'
import Dashboard from './Pages/Dashboard';
import API from './Store/Api/ApiService';
import crud_service from './Store/Api/CrudService';
import { useDispatch } from "react-redux";
import MyContext from './MyContext';
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';

const user = JSON.parse(localStorage.getItem("persist:root"))?.admin || "";
const isAuthenticated = user && JSON.parse(user)?.isAuthenticated;
const userData = user ? JSON.parse(user) : ""

const App = () => {

  const [accessSetting, setAccessSetting] = useState()
 
  const idleTimerRef = useRef(null);

  // const getMenuAll = () => {

  //   const formattedMenu = {}
  //     crud.getAll('menumaster',"",function(err,res){
  //       if(res?.data?.success===true) {
  //         const menu = res?.data?.data?.filter((item) => {
  //           return item.parent_id !== null;
  //         });
  //         menu?.map((item)=>{
  //           const cleanedURL = item?.url.replace(/[ \-&/]/g, "")
  //           formattedMenu[cleanedURL] = ["view", "add", "edit", "delete"]
  //         })
  //       }
  //       getAccessSetting(formattedMenu)


  //     });
  //   }


    const getAccessSetting = async () => {
      // if (userData?.admin?.data?.role == "staff") {
        await crud.getSingle('accesssettings', userData?.admin?.data?._id, (err, res) => {
          if (res?.data?.success === true) {
            setAccessSetting(res?.data?.data)
          }
        });
      // } else {
      //   setAccessSetting(formattedMenu)
      // }
    }
    const onIdle = async() => {
      // console.log("User is idle. Logging out...");
      // Log out the user or take any other action
      await api.logout(dispatch);
      window.location.href="/login"
    };

  const api = new API();
  const crud = new crud_service();
  const dispatch = useDispatch();
  const checkEveryLogin = async () => {
    await crud.create('verifylogin', '', async (err, res) => {
      if (res?.data?.success === true) {

      } else {
        await api.logout(dispatch);
      }

    });
  };

  //init render
  useEffect(() => {
    checkEveryLogin();
    // getMenuAll()
    getAccessSetting()
  }, []);


  return (
    <React.Fragment>
      <MyContext.Provider value={accessSetting}>
      <IdleTimerProvider ref={idleTimerRef} timeout={20 * 60 * 1000} onIdle={onIdle} debounce={500}>
        <Dashboard />
        </IdleTimerProvider>
      </MyContext.Provider>
    </React.Fragment>
  )
}

export default App;